/**
 * Utilities specific to NESCPA
 */

/**
 * Dependencies
 */

import convertToLocalTime from '../../vendor/imarc/inkwell/resources/scripts/modules/datetimeHandler';

import isExternalURL from '../../vendor/imarc/inkwell/resources/scripts/modules/linkObserver';

const timeElements = [...document.querySelectorAll('time')];

window.addEventListener('content-populated', () => {
  const anchors = document.querySelectorAll('a');
   timeElements.forEach(convertToLocalTime);
    if (anchors.length) {
        anchors.forEach(anchor => {
            if (isExternalURL(anchor)) {
                anchor.classList.add('js-external');
            }
        });
    }
});
